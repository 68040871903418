/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.8.2/lightgallery.min.js
 * - /npm/lightgallery@2.8.2/plugins/thumbnail/lg-thumbnail.min.js
 * - /npm/lightgallery@2.8.2/plugins/video/lg-video.min.js
 * - /npm/shaka-player@3.3.19/dist/shaka-player.compiled.min.js
 * - /npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js
 * - /npm/imagesloaded@5.0.0/imagesloaded.pkgd.min.js
 * - /npm/lightgallery@2.8.2/plugins/zoom/lg-zoom.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
